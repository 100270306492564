<template>
  <div>
    <Header />
    <div class="bg-mid-grey mt-5">
      <div class="grid-container">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pt-5 pb-5">
            <h1 class="page-header">Instructions</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-light-grey">
      <div class="grid-container">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pt-5">
            <hr class="short wide blue-line" />
          </div>
        </div>
        <InstuctionsOne v-if="slide === 1" />
        <InstuctionsTwo v-if="slide === 2" />
        <InstuctionsThree v-if="slide === 3" />
        <InstuctionsFour v-if="slide === 4" />
        <InstuctionsFive v-if="slide === 5" />
        <div class="grid-x grid-padding-x pb-20">
          <div class="cell small-12 medium-1">
            <span v-if="slide === 1">
              <img class="link" width="30px" src="../assets/arrow_buttons/back_arrow_inactive.png" alt="Prev" />
            </span>
            <span v-else @click="slideLeft">
              <img class="link" width="30px" src="../assets/arrow_buttons/back_arrow_active.png" alt="Prev" />
            </span>
          </div>
          <div class="cell small-12 medium-1 medium-offset-10 text-right">
            <span v-if="slide === 5">
              <img class="link" width="30px" src="../assets/arrow_buttons/forward_arrow_inactive.png" alt="Next" />
            </span>
            <span @click="slideRight" v-else>
              <img class="link" width="30px" src="../assets/arrow_buttons/forward_arrow_active.png" alt="Next" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InstuctionsOne from '@/components/Slides/InstructionsOne.vue';
import InstuctionsTwo from '@/components/Slides/InstructionsTwo.vue';
import InstuctionsThree from '@/components/Slides/InstructionsThree.vue';
import InstuctionsFour from '@/components/Slides/InstructionsFour.vue';
import InstuctionsFive from '@/components/Slides/InstructionsFive.vue';
import Header from '@/components/Header.vue';

export default {
  name: 'Instructions',
  components: {
    Header,
    InstuctionsOne,
    InstuctionsTwo,
    InstuctionsThree,
    InstuctionsFour,
    InstuctionsFive,
  },
  data() {
    return {
      slide: 1,
    };
  },
  methods: {
    slideLeft() {
      this.slide -= 1;
    },
    slideRight() {
      this.slide += 1;
    },
  },
};
</script>
